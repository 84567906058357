var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-h4"},[_vm._v("Управление категориями")]),_c('v-divider',{staticClass:"my-2"}),_c('v-card',[_c('v-card-title',[_vm._v(" Категории "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":"","clearable":""},on:{"input":_vm.onInputFilter},model:{value:(_vm.table.filter.search),callback:function ($$v) {_vm.$set(_vm.table.filter, "search", $$v)},expression:"table.filter.search"}}),_c('v-btn',{staticClass:"ml-2 mt-1",attrs:{"icon":""},on:{"click":_vm.toggleAdvancedSearch}},[_c('v-icon',[_vm._v(_vm._s(_vm.showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('transition',{attrs:{"name":"slide"}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdvancedSearch),expression:"showAdvancedSearch"}]},[_c('v-radio-group',{attrs:{"row":"","disabled":_vm.busy,"dense":""},on:{"change":_vm.onInputFilter},model:{value:(_vm.table.filter.activity),callback:function ($$v) {_vm.$set(_vm.table.filter, "activity", $$v)},expression:"table.filter.activity"}},[_c('v-radio',{attrs:{"label":"Все","value":"All"}}),_c('v-radio',{attrs:{"label":"Активные","value":"Active"}}),_c('v-radio',{attrs:{"label":"Заблокированные","value":"Blocked"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"can",rawName:"v-can:access",value:({type:'acl', id:'Admin'}),expression:"{type:'acl', id:'Admin'}",arg:"access"}],attrs:{"fab":"","color":"success","disabled":_vm.busy,"to":{ name: 'category.create' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Добавить категорию")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"can",rawName:"v-can:access",value:({type:'acl', id:'Admin'}),expression:"{type:'acl', id:'Admin'}",arg:"access"}],staticClass:"ml-1",attrs:{"fab":"","color":"error","disabled":!_vm.hasSelected},on:{"click":_vm.deleteSelectedAction}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}])},[_c('span',[_vm._v("Удалить группу")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"can",rawName:"v-can:access",value:({type:'acl', id:'Admin'}),expression:"{type:'acl', id:'Admin'}",arg:"access"}],staticClass:"ml-1",attrs:{"disabled":!_vm.hasSelected,"fab":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-list-status")])],1)]}}],null,true)},[_c('span',[_vm._v("Изменить статус")])])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.setIsActiveSelectedAction(true)}}},[_c('v-list-item-title',[_vm._v("Активный")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.setIsActiveSelectedAction(false)}}},[_c('v-list-item-title',[_vm._v("Заблокирован")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.busy,"headers":_vm.table.headers,"items":_vm.items,"server-items-length":_vm.itemsTotalCount,"footer-props":_vm.table.footerProps,"options":_vm.table.options,"show-select":"","item-class":_vm.tableItemClass},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortenId")(item.id))+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive===true)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-pause-circle ")])]}},{key:"item.meta.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.meta.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","text":"","small":"","to":{ name: 'category.edit', params: { id: item.id} }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.deleteAction(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}]),model:{value:(_vm.table.selectedItems),callback:function ($$v) {_vm.$set(_vm.table, "selectedItems", $$v)},expression:"table.selectedItems"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.deleteDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "show", $$v)},expression:"deleteDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"title error--text"},[_vm._v("Удаление данных")]),_c('v-card-text',{staticClass:"my-2"},[_c('div',{staticClass:"text-body-2"},[_vm._v(" Вы действительно хотите произвести удаление данных? ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" Продолжить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog.show = false}}},[_vm._v(" Отмена ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }