<template>
  <div>
    <h1 class="text-h4">Управление категориями</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-title>
        Категории
        <v-spacer></v-spacer>
        <v-text-field
          v-model="table.filter.search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          clearable
          @input="onInputFilter"
        ></v-text-field>
        <v-btn icon class="ml-2 mt-1" @click="toggleAdvancedSearch">
          <v-icon>{{ showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <transition name="slide">
        <v-card-text v-show="showAdvancedSearch">
          <v-radio-group
            v-model="table.filter.activity"
            row
            :disabled="busy"
            dense
            @change="onInputFilter"
          >
            <v-radio label="Все" value="All"/>
            <v-radio label="Активные" value="Active"/>
            <v-radio label="Заблокированные" value="Blocked"/>
          </v-radio-group>
        </v-card-text>
      </transition>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="success"
              :disabled="busy"
              v-bind="attrs"
              :to="{ name: 'category.create' }"
              v-on="on"
            >
              <v-icon>mdi-file-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Добавить категорию</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="error"
              :disabled="!hasSelected"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedAction"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить группу</span>
        </v-tooltip>
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-can:access="{type:'acl', id:'Admin'}"
                  :disabled="!hasSelected"
                  v-bind="attrs"
                  fab
                  class="ml-1"
                  v-on="{ ...tooltip, ...menu}"
                >
                  <v-icon>mdi-list-status</v-icon>
                </v-btn>

              </template>
              <span>Изменить статус</span>
            </v-tooltip>

          </template>
          <v-list>
            <v-list-item @click="setIsActiveSelectedAction(true)">
              <v-list-item-title>Активный</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setIsActiveSelectedAction(false)">
              <v-list-item-title>Заблокирован</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
      <v-data-table
        v-model="table.selectedItems"
        :loading="busy"
        :headers="table.headers"
        :items="items"
        :server-items-length="itemsTotalCount"
        :footer-props="table.footerProps"
        :options.sync="table.options"
        show-select
        :item-class="tableItemClass"
      >
        <template v-slot:item.id="{ item }">
          {{ item.id | shortenId }}
        </template>

        <template v-slot:item.isActive="{ item }">
          <v-icon v-if="item.isActive===true" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-else small color="warning">
            mdi-pause-circle
          </v-icon>
        </template>
        <template v-slot:item.meta.updatedAt="{ item }">
          {{ item.meta.updatedAt | formatDateTime }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            fab
            text
            small
            :to="{ name: 'category.edit', params: { id: item.id} }"
            class="mr-1"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            @click="deleteAction(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteItemConfirm">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import { Asc, Desc } from '@/model/SortOrder'
import Page from '@/model/Page'
import _ from 'lodash'
import CategoryFilter from '@/model/category/CategoryFilter'

export default {
  ...dashboardPageCommonAttributes,
  name: 'CategoryListPage',
  data: function () {
    return {
      table: {
        selectedItems: [],
        options: {
          sortBy: ['name'],
          sortDesc: [false],
          itemsPerPage: 30
        },
        headers: [
          { text: 'Id', align: 'left', value: 'id' },
          { text: 'Активно', value: 'isActive' },
          { text: 'Название', value: 'name' },
          { text: 'Изменен', value: 'meta.updatedAt' },
          { text: 'Действия', value: 'actions', sortable: false, align: 'right' }
        ],
        filter: {
          search: '',
          activity: null
        },
        footerProps: {
          itemsPerPageOptions: [10, 15, 30, 50],
          itemsPerPageText: 'Строк на странице:',
          // showCurrentPage:true,
          showFirstLastPage: true
        }
      },
      deleteDialog: {
        show: false,
        value: []
      }
    }
  },
  computed: {
    ...mapState('category_list', ['items', 'busy', 'itemsTotalCount', 'filter', 'showAdvancedSearch']),
    hasSelected() {
      return this.table.selectedItems.length > 0
    }
  },
  watch: {
    'table.options': {
      handler() {
        this.loadTable()
      },
      deep: true
    }
  },
  created() {
    this.table.filter.search = this.filter.search
    this.table.filter.activity = this.filter.activity
  },
  methods: {
    ...mapMutations('category_list', {
      setPage: 'SET_PAGE',
      setFilter: 'SET_FILTER',
      toggleAdvancedSearch: 'TOGGLE_ADVANCED_SEARCH'
    }),
    ...mapActions('category_list', ['fetchData', 'deleteCategory', 'setIsActive']),
    loadTable() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.table.options

      const sort = []

      sortBy.forEach((field, index) => {
        sort.push({
          field,
          order: (sortDesc[index]) ? Desc : Asc
        })
      })

      const p = Page.create({
        page: page - 1,
        size: itemsPerPage,
        sort
      })

      this.setPage({ page: p })

      this.fetchData()
    },
    deleteAction(item) {
      this.deleteDialog.value = [item]
      this.deleteDialog.show = true
    },
    deleteSelectedAction() {
      this.deleteDialog.value = this.table.selectedItems
      this.deleteDialog.show = true
    },
    deleteItemConfirm() {
      const ids = this.deleteDialog.value.map((item) => item.id)

      this.deleteCategory({ ids })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.deleteDialog.value = []
          this.deleteDialog.show = false
          this.table.selectedItems = []
        })
    },
    setIsActiveSelectedAction(isActive) {
      const ids = this.table.selectedItems
        .filter((item) => item.isActive !== isActive)
        .map((item) => item.id)

      if (ids.length === 0) {
        this.table.selectedItems = []

        return
      }

      this.setIsActive({ ids, isActive })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.table.selectedItems = []
        })
    },
    onInputFilter: _.debounce(function () {
      const filter = CategoryFilter.create({
        ...this.table.filter
      })

      this.table.options.page = 1

      this.setFilter({ filter })

      this.loadTable()
    }, 500),
    tableItemClass(item) {
      if (item.isActive === false) {
        return ['blocked']
      }

      return ''
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Категории'
      }
    }
  }
}
</script>
